import React, { useState, useEffect,useRef} from 'react';
import RecordRTC from 'recordrtc';
import '../../App.css';
import { Box, Button, Stepper, Step, StepLabel, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../../styles/ParagraphEditor.css';
import '../../styles/QuestionAnswerPage.css';
import '../../styles/prescreen.css';
import { BlobServiceClient, AnonymousCredential } from '@azure/storage-blob';
 
function getSteps() {
  return ['Assessment-1', 'Assessment-2', 'Assessment-3'];
}
 
function getStepTitle(stepIndex) {
  const steps = getSteps();
  return steps[stepIndex] || 'Assessment';
}
 
function Prescreening() {
  const location = useLocation();
  const navigate = useNavigate();
  const [azureBlobClient, setAzureBlobClient] = useState(null);
  const [paragraph, setParagraph] = useState("");
  const [paragraph1, setParagraph1] = useState([]);
  const [paragraph2, setParagraph2] = useState("");
  const [paragraph3, setParagraph3] = useState("");
  const [timeLeft, setTimeLeft] = useState(300);
  const [dialoguetimeLeft, setdialogueTimeLeft] = useState(2400);
  const [wordCount, setWordCount] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const[bloburl,setbloburl] = useState('');
  const [assessmentRecording, setAssessmentRecording] = useState(null); 
  const [recordedVideo, setRecordedVideo] = useState(null); 
  const [timer, setTimer] = useState(0); 
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const assessmentData = useRef({ Assessment1: {}, Assessment2: {}, Assessment3: {} });
  const [isLoading, setIsLoading] = useState(false);
  const { candidateid ,position} = location.state || {};
  const [dialogueAnswer, setDialogueAnswer] = useState("");
  const [timerInterval, setTimerInterval] = useState(null);
  const [matchedCount, setMatchedCount] = useState(0);
  const [level, setLevel] = useState('');
  const [results, setResults] = useState([]);
  const [marks, setMarks] = useState(null);
  const screenSharingStarted = useRef(false);

  const wordsLen = (str) => {
    const array = str.trim().split(/\s+/);
    return array.length;
  };
  const totalCount = wordsLen(paragraph3);
  const handleDialogueChange = (event) => {
    setDialogueAnswer(event.target.value);
  };
 
  const checkGrammar = async () => {
    const lines = dialogueAnswer.split('\n'); // Split the dialogue into lines
    const results = [];
    let totalErrors = 0;
 
    for (const [index, line] of lines.entries()) {
      if (line.trim() === '') continue; // Skip empty lines
 
      try {
        const response = await fetch('https://api.languagetool.org/v2/check', { // Replace with actual URL
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: new URLSearchParams({ text: line, language: 'en-US' }),
        });
 
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
 
        const data = await response.json();
        results.push({ line: index + 1, errors: data.matches });
        totalErrors += data.matches.length;
      } catch (error) {
        console.error(`Error checking line ${index + 1}:`, error);
        results.push({ line: index + 1, errors: [] }); // Add an empty result if there's an error
      }
    }
 
    // Adjust the marking logic based on total errors
    const calculatedMarks = totalErrors > 20 ? 100 : 0;
 
    setResults(results);
    setMarks(calculatedMarks);
  };
 

  const originalConversation = [
    { speaker: 'Client', text: 'Hello' },
    { speaker: 'Customer Service Representative', text: 'Hi Good Morning!! This is Arjun from Bigshop, your good name and how can I help you sir?' },
   
    { speaker: 'Client', text: 'Yes Good Morning, Gokul Here and I have a big problem with the product I bought from your site this week' },
    { speaker: 'Customer Service Representative', text: 'Moments with me Mr.Gokul, I will review your request now' },
   
    { speaker: 'Client', text: 'Ok' },
    { speaker: 'Customer Service Representative', text: 'Your request no:123 was on Wednesday correct Gokul?' },
   
    { speaker: 'Client', text: 'Yup' },
    { speaker: 'Customer Service Representative', text: 'Ok, Mr.Gokul can you explain to me what is the problem exactly?' },
   
    { speaker: 'Client', text: 'I didn’t like the product and I didn’t get it as the same picture on the company website, you are liars!' },
    { speaker: 'Customer Service Representative', text: ' I apologize very much to you Gokul but on our website, we put pictures of real products only, and the proof is the positive comments and evaluations from all our customers in Saudi Arabia' },
   
    { speaker: 'Client', text: 'I don’t believe it; I believe my eyes who see the product' },
    { speaker: 'Customer Service Representative', text: 'Sorry, Mr. Gokul I’m not accusing you of lying at all, and I will solve this problem immediately, can you please explain to me in detail how the product is different from the picture?' },
   
    { speaker: 'Client', text: ' In the picture, the size of the product is bigger than what I received' },
    { speaker: 'Customer Service Representative', text: 'Ok, Gokul if you opened our website, you would find the sizes written in detail in the product description, if you can look at it now while you are with me on the line?' },
   
    { speaker: 'Client', text: 'Ok' },  
    { speaker: 'Customer Service Representative', text: 'Take your time, Gokul' },
   
    { speaker: 'Client', text: 'it is Written but the product in the picture is very big than the size that is written in the description?' },
    { speaker: 'Customer Service Representative', text: 'This is because of the angle of photography Gokul because we in the company must show all the details of the product to the customer, so the pictures are a little close to the product to show all the details to the customers, and we provide the product with a specific and clear description of materials and sizes and advantages and everything so that the customer knows the product that he will buy, are you still with me Gokul?' },
   
    { speaker: 'Client', text: 'yes' },
    { speaker: 'Customer Service Representative', text: 'Are there any other differences than the size Mr.Gokul?' },
   
    { speaker: 'Client', text: 'I don’t know how to use this product; I mean the way I used it and it didn’t work' },
    { speaker: 'Customer Service Representative', text: 'There is no problem Gokul, I will send you an accurate description of the method of use and will follow up with you one of the customer service representatives in the department of inquiries to clarify all the details and information about the product' },
   
    { speaker: 'Client', text: 'Pretty good!' },
    { speaker: 'Customer Service Representative', text: 'Do you have any other complaints, Mr.Gokul?' },
   
    { speaker: 'Client', text: 'I wanted to complain to the shipping representative who got me this product' },
    { speaker: 'Customer Service Representative', text: 'Moments with me, Gokul now I will determine who is responsible for your shipping operation' },
   
    { speaker: 'Client', text: 'OK I am waiting for you' },
    { speaker: 'Customer Service Representative', text: 'I apologize for being late, moments Gokul' },
   
    { speaker: 'Client', text: 'Ok' },
    { speaker: 'Customer Service Representative', text: 'Now we will review with you some data about the shipment, ok?' },
   
    { speaker: 'Client', text: 'Ok' },
    { speaker: 'Customer Service Representative', text: 'If you can check the delivery information on the bill' },
   
    { speaker: 'Client', text: 'The product was supposed to be delivered to me at 6 p.m., but I was surprised that the agent was two hours late!!!' },
    { speaker: 'Customer Service Representative', text: 'Ok Gokul, can you tell me the number of the representative on the bill?' },
   
    { speaker: 'Client', text: 'Moments… Agent number is (95520)' },
    { speaker: 'Customer Service Representative', text: 'OK Gokul, can you tell me now about the whole problem, go-ahead' },
   
    { speaker: 'Client', text: 'The representative was two hours behind schedule, and when I received the shipment was untidy and once humiliated, and unacceptably spoke to me!' },
    { speaker: 'Customer Service Representative', text: 'I apologize to you very much Mr.Gokul, and the necessary work will be done, and strict measures will be taken with the representative immediately, and an apology from our company to you we will offer you a 50% discount on any product you want from our site and shipping the product is free' },
   
    { speaker: 'Client', text: 'nice thank you' },
    { speaker: 'Customer Service Representative', text: 'I’m always at your service, Mr.Gokul, do you have any complaints or other inquiries?' },
   
    { speaker: 'Client', text: 'No' },
    { speaker: 'Customer Service Representative', text: 'Ok, Mr.John a customer service representative will contact you from the department of inquiries about the method of using the product as soon as possible, I wish you a happy day' },
 
    { speaker: 'Client', text: 'thanks a lot' },
    { speaker: 'Customer Service Representative', text: ' I am pleased to serve you, thank you for contacting the customer service of the company Bigshop.' }
  ];
 
  const questions = [
    'Laptop with Internet Connection',
    'I3 4th gen or above Processor',
    '120 GB SSD',
    'Minimum 8 GB RAM',
    'Antivirus Installed',
    'Broadband Internet',
    'Webcam and mic in condition',
    'Windows 10 and above'
  ];
  useEffect(() => {
    const initializeAzureBlobClient = async () => {
      const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN; // Replace with provided SAS token
      const blobServiceClient = new BlobServiceClient(`${process.env.REACT_APP_BLOB_SERVICE_URL}/?${sasToken}`, new AnonymousCredential());
      setAzureBlobClient(blobServiceClient);
    };
 
    initializeAzureBlobClient();
  }, []);
 
  useEffect(() => {
    if (candidateid) {
      fetch(`${process.env.REACT_APP_VIDEO_BACKEND_URL}/api/get_paragraph/${candidateid}/${position}`)
        .then(response => response.json())
        .then(data => {
          if (data.paragraph3) {
            setParagraph3(data.paragraph3);
          }
          if (data.level) {
            setLevel(data.level);
          }
          if (data.paragraph2) {
            setParagraph2(data.paragraph2);
          }
          if (data.paragraph1) {
            const at = data.paragraph1;
            const questions = at.map(item => ({ question:item.question,
             
              selectedType: item.selectedType
            }));
            setParagraph1(questions);
          }
        })
        .catch(error => {
          console.error('Error fetching paragraph:', error);
        });
    }
  }, [candidateid]);
 
  useEffect(() => {
    // Update the word count
    setWordCount(paragraph.split(/\s+/).filter(Boolean).length);
 
    // Update the matched count
    const originalWords = new Set(paragraph3.toLowerCase().split(/\s+/).filter(Boolean));
    const inputWords = new Set(paragraph.toLowerCase().split(/\s+/).filter(Boolean));
    const matchedWords = [...originalWords].filter(word => inputWords.has(word));
    setMatchedCount(matchedWords.length);
  }, [paragraph, paragraph3]);
 
  
 

 
  useEffect(() => {
    const words = paragraph.split(/\s+/);
    setWordCount(words.length);
  }, [paragraph]);
 
  useEffect(() => {
    if (!screenSharingStarted.current) {
      startAssessmentScreenRecording();
      screenSharingStarted.current = true;
    }
  }, []);
 
 
 
  useEffect(() => {
    if (activeStep === 2 && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
 
      return () => clearInterval(timer);
    } else if (activeStep === 1 && dialoguetimeLeft > 0) {
      const timers = setInterval(() => {
        setdialogueTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timers);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
 
      return () => clearInterval(timers);
    }
  }, [activeStep, timeLeft, dialoguetimeLeft]);
 
  useEffect(() => {
    document.title = getStepTitle(activeStep);
  }, [activeStep]);
 
  const handleChange = (event) => {
    setParagraph(event.target.value);
  };
 
  const handleContextMenu = (event) => {
    event.preventDefault();
  };
 
  const handleAnswerChange = (question, answer) => {
    setAnswers({ ...answers, [question]: answer });
  };
 
  const handleNext = () => {
    if (activeStep === 0) {
      assessmentData.current.Assessment1 = { ...answers, Status: 'Completed' };
    }
   
    if (activeStep === 1) {
      checkGrammar();
      assessmentData.current.Assessment2 = { DialogueAnswer: dialogueAnswer, Status: 'Completed' };
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
 
  const startAssessmentScreenRecording = () => {
   
   navigator.mediaDevices.getDisplayMedia({ video: true })
      .then((stream) => {
        const recorder = new RecordRTC(stream, { type: 'video' });
        recorder.startRecording();
        setAssessmentRecording(recorder);
        startTimer();
      })
      .catch((error) => {
        console.error('Error accessing screen media:', error);
      });
  };
 
  const stopAssessmentScreenRecording = () => {
    if (assessmentRecording) {
      assessmentRecording.stopRecording(() => {
        setRecordedVideo(assessmentRecording.getBlob());
        const blob = assessmentRecording.getBlob();
        uploadRecordedVideo(blob);
        assessmentRecording.reset();
        setAssessmentRecording(null);
        stopTimer();
        setIsLoading(true);
      });
    }
  };
 
  const stopTimer = () => {
    clearInterval(timerInterval);
    setTimer(0); // Reset the timer
  };
  const uploadRecordedVideo = async (blob) => {
    try {
      if (!azureBlobClient) {
        console.error('Azure Blob Service Client is not initialized.');
        return;
      }
      const containerName = process.env.REACT_APP_CONTAINER_NAME;     
      const containerClient = azureBlobClient.getContainerClient(containerName);
      const blobName = `Assessment_recorded_video_${generateUniqueId()}.webm`; // Unique name with ID
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      await blockBlobClient.uploadData(blob);
      
      const dburl = `${process.env.REACT_APP_BLOB_SERVICE_URL}`+"/"+`${process.env.REACT_APP_CONTAINER_NAME}/` + blobName;
     setbloburl(dburl);  
    } catch (error) {
      console.error('Error uploading recorded video:', error);
    }
  };
  const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };
  const handleSubmit = async() => {   
    handleNext();
    stopAssessmentScreenRecording();
};
 
assessmentData.current.Assessment3 = {
      Paragraph: paragraph,
      Status: 'Completed',
      totalCount : totalCount,
      wordCount : wordCount,
      matchedWordCount: matchedCount,
};
assessmentData.current.Assessment2 = {
  dialogueAnswer:dialogueAnswer,
  results:results,
  marks:marks
}
   
    const data = {
      Assessment1: assessmentData.current.Assessment1,
      Assessment2: assessmentData.current.Assessment2,
      Assessment3: assessmentData.current.Assessment3,    
      videoRecording: bloburl,
      status:"Completed"
    };
    if (timeLeft === 0) {
      // console.log('Submitted Paragraph:', paragraph);
    }
 
if (bloburl !== '') {
 (async () => {
try {
const response = await fetch(`${process.env.REACT_APP_VIDEO_BACKEND_URL}/api/submit-assessment/${candidateid}/${position}`, {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
});
 
if (response.ok) {
  setIsLoading(false);
    navigate('/InterviewEnd',{state: {candidateid:candidateid ,position:position}});
 
} else {
  const errorData = await response.json();
  console.error('Error submitting assessment:', errorData);
  Swal.fire({
    title: 'Error',
    text: 'There was an error submitting the assessment. Please try again.',
    icon: 'error',
    confirmButtonText: 'OK'
  });
}
} catch (error) {
console.error('Error submitting assessment:', error);
Swal.fire({
  title: 'Error',
  text: 'There was an error submitting the assessment. Please try again.',
  icon: 'error',
  confirmButtonText: 'OK'
});
}
})();
}
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer(prevTimer => prevTimer + 1);
    }, 1000);
    setTimerInterval(interval);
  };
 
 
  return (
    <Box>
 
      <div className='container-fluid' style={{padding:"0px"}}>
      <Stepper className='stepper-card' activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
          <Typography variant="h6" >
          <span role="img" aria-label="timer">🔴</span> {formatTime(timer)}
      </Typography>
        </Stepper> 
     </div>
 
 
      <Box>
        {activeStep === 0 && (
          <div className="prescreen-container">
            {paragraph1.map((item, index) => (
                <div key={index} className="prescreen-question">
                  <label>{item.question}:</label>
                  <div className="prescreen-options">
                    {item.selectedType === 'text' ? (
                      <input 
                        type="text" 
                        placeholder={`Enter Text`} 
                        onChange={(e) => handleAnswerChange(item.question, e.target.value)} 
                      />
                    ) : (
                      <>
                        <input 
                          type="radio" 
                          id={`${item.question}Yes`} 
                          name={item.question} 
                          value="Yes" 
                          onChange={() => handleAnswerChange(item.question, 'Yes')} 
                        />
                        <label htmlFor={`${item.question}Yes`}>Yes</label>
                        <input 
                          type="radio" 
                          id={`${item.question}No`} 
                          name={item.question} 
                          value="No" 
                          onChange={() => handleAnswerChange(item.question, 'No')} 
                        />
                        <label htmlFor={`${item.question}No`}>No</label>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}
   
 
        {activeStep === 1 && (
          <div className="card-container">
            <div className="card conversation-card">
              <div className="card-body">
                <h5 className="card-title">Sample Questionnaire</h5>
                {originalConversation.map((dialogue, index) => (
 
                  <div key={index} className={`dialogue ${dialogue.speaker.toLowerCase()}`}>
                    <p><strong>{dialogue.speaker}: </strong>{dialogue.text}</p>
                  </div>
                ))}
                <div>
                </div>
              </div>
            </div>
            <div className="card answer-card">
              <div className="card-body">
                <h2 style={{textAlign:'center'}}>Time Left: {formatTime(dialoguetimeLeft)}</h2>
                <p style={{ fontWeight: 'bold', color: 'green' }}>
                <h5 className="card-title">Dialogue Writing - 40 minutes</h5>
                </p>
                <p onContextMenu={handleContextMenu}>{paragraph2}</p>
 
                <p style={{ fontWeight: 'bold', color: 'red' }}>
                  Hope you read the above passage completely, now assume yourself in the below situation, Write the professional conversation between you and the customer?
                </p>
                <textarea
                  className="form-control"
                  placeholder="Your answer"
                  rows={15}
                  value={dialogueAnswer}
                  onChange={handleDialogueChange}
                  onPaste={(e) => e.preventDefault()}
                  onCopy={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                  autocomplete="off" 
                />
              </div>
            </div>
          </div>
        )}
 
        {activeStep === 2 && (
          <div className="paragraph-editor-container">
            <div className="countdown-timer">
              <h2>Time Left: {formatTime(timeLeft)}</h2>
            </div>
            <div className="cards-container">
              <div className="cards paragraph-preview">
                <h2>Original Paragraph</h2>
                <p onContextMenu={handleContextMenu}>{paragraph3}</p>
              </div>
              <div className="cards paragraph-input">
              <p style={{ fontWeight: 'bold', color: 'green' }}>
              <h5 className="card-title">Typing Test - 5 minutes</h5>
              </p>
                <h2>Write the above paragraph within the time</h2>
                <textarea
                    rows="15"
                    value={paragraph}
                    onChange={handleChange}
                    placeholder="Enter or edit the paragraph here..."
                    disabled={timeLeft === 0}
                    onPaste={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    onCut={(e) => e.preventDefault()}
                  />
                <p className="word-count">s
                  Word Count: {wordCount} / {totalCount}
                </p>
                {wordCount < 150 && (
                  <p className="word-count-alert">
                    Please write at least 150 words.
                  </p>
                )}
                <p>Mathched count : {matchedCount}</p>
              </div>
            </div>
          </div>
        )}
 
        {activeStep < steps.length - 1 && (
          <Box textAlign="center" mt={2}>
            <Button
              variant="contained"
              className='buttonNext'
              onClick={handleNext}
            >
              {isLastStep ? 'Submit' : 'Next'}
            </Button>
          </Box>
        )}
 
        {isLastStep && (
          <Box textAlign="center" mt={2}>
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        )}
        {isLoading && (
        <div className="overlay">
           <div className="spinner-container">
          <div className="spinner"></div>
          <div className="loading-text">Please wait, processing...</div>
          </div>
        </div>
      )}
       
      </Box>
    </Box>
  );
}
 
export default Prescreening;