import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import InterviewEnd from './components/InterviewEnd';
import LinkExpired from './components/LinkExpired';
import WebcamNew from './components/WebcamNew';
import InterviewNotStarted from './components/notstarted';
import InvalidURL from './components/invalid';
import Concernsscreen from './components/consentform';
import Prescreening from './components/prescreening';
import EquipmentCheck from './components/Checkequipments';

const App = () => {
  const [isTabActive, setIsTabActive] = useState(true); // Flag to track tab activity
  const [arrowsVisible, setArrowsVisible] = useState(false);
  useEffect(() => {
    // Event listener for tab visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // User switched to another tab, trigger fraud detection alert
        alert('Security Alert : You are not allowed to switch tabs.');
        setIsTabActive(false);
      } else {
        setIsTabActive(true);
      }
    };
 
    document.addEventListener('visibilitychange', handleVisibilityChange);
 
    // Clean up event listener on unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return (
    <Routes>
      <Route path="/*" element={<Concernsscreen />} />
      <Route path="/EquipmentCheck" element={<EquipmentCheck/>}/>
      <Route path="/prescreening" element={<Prescreening />} />
      <Route path="/WebcamNew" element={<WebcamNew />} />
      <Route path="/InterviewEnd" element={<InterviewEnd />} />
      <Route path="/LinkExpired" element={<LinkExpired />} />
      <Route path="/notstarted" element={<InterviewNotStarted />} />
      <Route path="/invalid" element={<InvalidURL />} />
    </Routes>
  );
};

export default App;


