

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faStar } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import '../../styles/InterviewEnd.css';
import { useLocation } from 'react-router-dom';

const MeetingEndScreen = () => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [isOkClicked, setIsOkClicked] = useState(false);
  const location = useLocation();
  const canid = location.state || "";
  const candidate_id = canid.candidateid[0];

  const handleStarClick = (ratingValue) => {
    if (!isOkClicked) {
      setRating(ratingValue);
      setIsOkClicked(true);
      
      // Send rating to the backend
      axios.put(`${process.env.REACT_APP_VIDEO_BACKEND_URL}/api/rate`, { rating: ratingValue, candidate_id: candidate_id})
        .then(response => {
          // console.log('Rating submitted successfully');
        })
        .catch(error => {
          console.error('Error submitting rating:', error);
        });
    }
  };

  return (
    <div className="meeting-end-container">
      <div className="meeting-end-content">
        <div className="meeting-icon-container">
          <FontAwesomeIcon icon={faMicrophone} className="meeting-icon" />
        </div>
        <div className="meeting-end-header">
          <h1 className="meeting-end-title">Interview Ended</h1>
          <p className="meeting-end-text">
            Thank you for attending the Interview.
          </p>
          <div className="star-rating">
            {[...Array(5)].map((_, index) => {
              const ratingValue = index + 1;
              return (
                <FontAwesomeIcon
                  key={index}
                  icon={faStar}
                  className="star-icon"
                  color={ratingValue <= (hover || rating) ? "gold" : "grey"}
                  onClick={!isOkClicked ? () => handleStarClick(ratingValue) : undefined}
                  onMouseEnter={() => !isOkClicked && setHover(ratingValue)}
                  onMouseLeave={() => !isOkClicked && setHover(0)}
                  style={{ cursor: isOkClicked ? 'default' : 'pointer' }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingEndScreen;

