import React, { useState, useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { MdMic, MdWeb, MdNetworkCheck, MdLanguage } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import '../../styles/equipmentcheck.css'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
// Custom CSS for layout

const EquipmentCheck = () => {
  const [browserChecked, setBrowserChecked] = useState(false);
  const [webcamChecked, setWebcamChecked] = useState(false);
  const [microphoneChecked, setMicrophoneChecked] = useState(false);
  const [networkChecked, setNetworkChecked] = useState(false);
  
  const location = useLocation();
  const { candidateid ,positiondata} = location.state || {};
  const [error, setError] = useState(null);
  const [browserCheckEnabled, setBrowserCheckEnabled] = useState(false);
  const [webcamCheckEnabled, setWebcamCheckEnabled] = useState(false);
  const [microphoneCheckEnabled, setMicrophoneCheckEnabled] = useState(false);
  const [networkCheckEnabled, setNetworkCheckEnabled] = useState(false);

  const [micErrorMessage, setMicErrorMessage] = useState('Microphone not available!'); // Track microphone error message
  const navigate = useNavigate();

  const checkBrowser = () => {
    const browser = getBrowserName();
    setBrowserChecked(browser !== 'Unknown');
  };

  const getBrowserName = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.includes('Chrome')) return 'Chrome';
    if (userAgent.includes('Firefox')) return 'Firefox';
    if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) return 'Safari'; // Avoid detecting Chrome as Safari
    if (userAgent.includes('Edge')) return 'Edge';
    return 'Unknown';
  };

  const checkWebcam = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setWebcamChecked(true);
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch(() => {
        setWebcamChecked(false);
      });
  };

  const checkMicrophone = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setMicrophoneChecked(true);
        setMicErrorMessage('');
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch(() => {
        setMicrophoneChecked(false);
        setMicErrorMessage('Microphone not available!');
        
        // Set a 5-second timer to change the message and uncheck the box
        setTimeout(() => {
          setMicErrorMessage('Enable your mic in system settings!');
          setMicrophoneCheckEnabled(false); // Uncheck the microphone checkbox
        }, 5000);
      });
  };

  const checkNetwork = () => {
    setNetworkChecked(navigator.onLine);
  };

  const handleNext = async () => {
    if (browserChecked && webcamChecked && microphoneChecked && networkChecked) {
        try {
            // Fetch candidate status from the backend
            const response = await axios.get(`${process.env.REACT_APP_VIDEO_BACKEND_URL}/api/schedule/check_candidate_status/${candidateid}/${positiondata}`);
         
            // Check if response data is present and has the expected structure
            const document = response.data;
            const Prescreening = document.Prescreening || [];
            const Prescreeningresult = document.Prescreeningresult || {};
            const ScreeningLevel1 = document['ScreeningLevel1'] || [];
      
            let prescreeningstartDate, prescreeninglinkDate, screenlevel1startdate, screenlevel1linkDate;
      
            // Check if Prescreening data exists and if it has the required properties
            if (document.Prescreening && document.Prescreening[0] && document.Prescreening[0].startDate) {
              prescreeningstartDate = new Date(document.Prescreening[0].startDate);
            }
      
            if (document.Prescreening && document.Prescreening[0] && document.Prescreening[0].linkDate) {
              prescreeninglinkDate = new Date(document.Prescreening[0].linkDate);
            }
      
            // Check if ScreeningLevel1 data exists and if it has the required properties
            if (document.ScreeningLevel1 && document.ScreeningLevel1[0] && document.ScreeningLevel1[0].startDate) {
              screenlevel1startdate = new Date(document.ScreeningLevel1[0].startDate);
            }
      
            if (document.ScreeningLevel1 && document.ScreeningLevel1[0] && document.ScreeningLevel1[0].linkDate) {
              screenlevel1linkDate = new Date(document.ScreeningLevel1[0].linkDate);
            }
      
      
            const currentDate = new Date();
      
      
            // Check Prescreening and Prescreeningresult conditions
            if (Prescreening.length > 0) {
                const prescreeningStatus = Prescreening[0].Status;
                const prescreeningResultStatus = Prescreeningresult.status;
      
                
                if (prescreeningStatus === "Active" || prescreeningStatus ==="Expired") {
                    if (prescreeningResultStatus === "Completed") {
                        if (ScreeningLevel1.length > 0) {
                            const screeningStatus = ScreeningLevel1[0].Status;
                            if (screeningStatus === "Active") {
                                if (currentDate >= screenlevel1startdate && currentDate <= screenlevel1linkDate) {
                                    navigate("/WebcamNew" , { state: { candidateid: candidateid,positiondata:positiondata }});
                                } else if (currentDate < screenlevel1startdate) {
                                    navigate("/notstarted");
                                } else {
                                    navigate("/LinkExpired");
                                }
                            } else if (screeningStatus === "Completed") {
                                navigate("/LinkExpired");
                            } else {
                                navigate("/prescreening", { state: { candidateid: candidateid , position:positiondata  } });
                            }
                        } else {
                            navigate("/LinkExpired");
                        }
                    } else {
                        if (currentDate >= prescreeningstartDate && currentDate <= prescreeninglinkDate) {
                            navigate("/prescreening", { state: { candidateid: candidateid,position:positiondata  }});
                        } else if (currentDate < prescreeningstartDate) {
                            navigate("/notstarted");
                        } else {
                            navigate("/LinkExpired");
                        }
                    }
                } else if (prescreeningStatus === "Completed") {
                    if (prescreeningResultStatus === "Completed") {
                        if (ScreeningLevel1.length > 0) {
                            const screeningStatus = ScreeningLevel1[0].Status;
                            if (screeningStatus === "Active") {
                                if (currentDate >= screenlevel1startdate && currentDate <= screenlevel1linkDate) {
                                    navigate("/WebcamNew" ,{ state: { candidateid: candidateid , positiondata:positiondata }});
                                } else if (currentDate < screenlevel1startdate) {
                                    navigate("/notstarted");
                                } else {
                                    navigate("/LinkExpired");
                                }
                            } else if (screeningStatus === "Completed") {
                                navigate("/LinkExpired");
                            } else {
                                navigate("/prescreening", { state: { candidateid: candidateid ,position:positiondata  } });
                            }
                        } else {
                            navigate("/LinkExpired");
                        }
                    } else {
                        navigate("/LinkExpired");
                    }
                } else if(prescreeningStatus === "Inactive") {
                  
                    navigate("/invalid");
                }
                else {            
                  navigate("/prescreening", { state: { candidateid: candidateid , position:positiondata  } });
              }
            } else {
      if(ScreeningLevel1.length>0)
      {
        navigate("/WebcamNew" ,{ state: { candidateid: candidateid , positiondata:positiondata }});
      }
      else
      {
        navigate("/prescreening", { state: { candidateid: candidateid ,position:positiondata  } });
      }
                
            }
      
        
        } catch (error) {
            // Handle errors
            console.error('Error checking candidate status:', error);
            setError(error);
        }
    }
  };

  // Handler for enabling or disabling checks when the checkbox is toggled
  const handleCheckboxChange = (type) => {
    switch (type) {
      case 'browser':
        setBrowserCheckEnabled(!browserCheckEnabled);
        if (!browserCheckEnabled) {
          checkBrowser(); // Enable and check if the checkbox is checked
        } else {
          setBrowserChecked(false); // Disable and reset check status if unchecked
        }
        break;
      case 'webcam':
        setWebcamCheckEnabled(!webcamCheckEnabled);
        if (!webcamCheckEnabled) {
          checkWebcam();
        } else {
          setWebcamChecked(false);
        }
        break;
      case 'microphone':
        setMicrophoneCheckEnabled(!microphoneCheckEnabled);
        if (!microphoneCheckEnabled) {
          checkMicrophone();
        } else {
          setMicrophoneChecked(false);
          setMicErrorMessage('');
        }
        break;
      case 'network':
        setNetworkCheckEnabled(!networkCheckEnabled);
        if (!networkCheckEnabled) {
          checkNetwork();
        } else {
          setNetworkChecked(false);
        }
        break;
      default:
        break;
    }
  }; 

  return (
    <div className="equipment-check-wrapper">
    <div className="equipment-check-container">
      <h2>Equipment Check</h2>
      <p>System Readiness Check</p>
      <p>Please select the checks you want to perform and wait for the result.</p>

      <div className="check-grid">
        <div className="check-item">
          <label>Browser Check</label>
          <MdLanguage size={50} />
          {browserChecked ? <FaCheckCircle color="green" /> : <p style={{ color: 'red' }}>Browser not supported!</p>}
          <input
            type="checkbox"
            checked={browserCheckEnabled}
            onChange={() => handleCheckboxChange('browser')}
          />
        </div>
        <div className="check-item">
          <label>Webcam Check</label>
          <MdWeb size={50} />
          {webcamChecked ? <FaCheckCircle color="green" /> : <p style={{ color: 'red' }}>Webcam not available!</p>}
          <input
            type="checkbox"
            checked={webcamCheckEnabled}
            onChange={() => handleCheckboxChange('webcam')}
          />
        </div>
        <div className="check-item">
          <label>Microphone Check</label>
          <MdMic size={50} />
          {microphoneChecked ? <FaCheckCircle color="green" /> : <p style={{ color: 'red' }}>{micErrorMessage}</p>}
          <input
            type="checkbox"
            checked={microphoneCheckEnabled}
            onChange={() => handleCheckboxChange('microphone')}
          />
        </div>
        <div className="check-item">
          <label>Network Check</label>
          <MdNetworkCheck size={50} />
          {networkChecked ? <FaCheckCircle color="green" /> : <p style={{ color: 'red' }}>Connect to WiFi!</p>}
          <input
            type="checkbox"
            checked={networkCheckEnabled}
            onChange={() => handleCheckboxChange('network')}
          />
        </div>
      </div>

      <button
        className="next-button"
        disabled={!(browserChecked && webcamChecked && microphoneChecked && networkChecked)}
        onClick={handleNext}
      >
        Next
      </button>
    </div>
    </div>
  );
};

export default EquipmentCheck;
