import React from 'react';
import '../../styles/LinkExpired.css'; // Import the CSS file for styling
import { FaHourglassEnd } from 'react-icons/fa'; // Import Font Awesome icon

const LinkExpiredScreen = () => {

  return (
    <div className="link-expired-container">
      <div className="link-expired-content">
        <div className="link-expired-icon">
          <FaHourglassEnd size={60} color="#ffffff" />
        </div>
        <h2 className="link-expired-heading">Link Expired</h2>
        <p className="link-expired-message">
          The link you are trying to access has expired.
        </p>
        <p className="link-expired-message">
          Please contact support for assistance or generate a new link.
        </p>
      </div>
    </div>
  );
};

export default LinkExpiredScreen;


