import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/concern.css'; 
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

const ConcernScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobilenumber, setMobilenumber] = useState('');
  const [pannumber, setPanumber] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [url, setUrl] = useState('');
  const [candidateId, setCandidateId] = useState('');
  const [error, setError] = useState(null);
  const [category, setCategory] = useState('');
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [position,setPosition] = useState('');
  const[datas,setDatas] = useState([]);
  const[candidateemail,setCandidateemail] = useState("");
 
  useEffect(() => {
    setUrl(window.location.href);
    const handleLocationChange = () => {
      const newUrl = window.location.href;
      setUrl(newUrl);
      const links = newUrl.split('/');
      setCandidateId(links[3]);
    };
 
    window.addEventListener('popstate', handleLocationChange);
    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, []);
 
  useEffect(() => {
    if (url) {
      const links = url.split('/');
      let str = links[4].replace(/\+/g, ' ');
      str.replace(/\+/g, ' ');
      setPosition(str);
      if (links[3] === "") {

        navigate("./invalid")
      }
 
      setCandidateId([links[3]]);    }
  }, [url]);
 
  useEffect(() => {
    const handleInterviewQuestion = async () => {
      try {
        if (!candidateId) {
          console.error('Candidate ID is not defined:', candidateId);
          return;
        }
 
        const response = await axios.get(`${process.env.REACT_APP_VIDEO_BACKEND_URL}/api/schedule/get_schedule/${candidateId}/${position}`);
        setDatas(response.data);
        console.log(response.data,"datas");
        setCategory(response.data.category);
        setCandidateemail(response.data.candidate_email);
 
      } catch (error) {
        console.error('Error fetching questions:', error);
        setError(error);
 
      }
    };
 
    handleInterviewQuestion();
  }, [candidateId]);
 
  const handleCheckboxClick = (e) => {
    e.preventDefault();
    setShowPopup(true);
  };
const handleSubmit = async (e) => {
    e.preventDefault();
   
    // Check if terms are accepted
    if (!termsAccepted) {
        alert('Please accept the terms and conditions');
        return;
    }

   
    // Check if candidate ID is provided
    if (!candidateId) {
        console.error('Candidate ID is not defined:', candidateId);
        return;
    }
 
    if (candidateemail !== email ) {
      Swal.fire({
        icon: 'error',
        title: 'Email Mismatch',
        text: 'The email you entered does not match with candidate email.',
    });
  }

  else{

    navigate('/EquipmentCheck', { state: { candidateid: candidateId,positiondata:position }});
    try {
      // Fetch candidate status from the backend
//       const response = await axios.get(`${process.env.REACT_APP_VIDEO_BACKEND_URL}/api/schedule/check_candidate_status/${candidateId}/${position}`);
   
//       // Check if response data is present and has the expected structure
//       const document = response.data;
//       const Prescreening = document.Prescreening || [];
//       const Prescreeningresult = document.Prescreeningresult || {};
//       const ScreeningLevel1 = document['ScreeningLevel1'] || [];

//       let prescreeningstartDate, prescreeninglinkDate, screenlevel1startdate, screenlevel1linkDate;

//       // Check if Prescreening data exists and if it has the required properties
//       if (document.Prescreening && document.Prescreening[0] && document.Prescreening[0].startDate) {
//         prescreeningstartDate = new Date(document.Prescreening[0].startDate);
//       }

//       if (document.Prescreening && document.Prescreening[0] && document.Prescreening[0].linkDate) {
//         prescreeninglinkDate = new Date(document.Prescreening[0].linkDate);
//       }

//       // Check if ScreeningLevel1 data exists and if it has the required properties
//       if (document.ScreeningLevel1 && document.ScreeningLevel1[0] && document.ScreeningLevel1[0].startDate) {
//         screenlevel1startdate = new Date(document.ScreeningLevel1[0].startDate);
//       }

//       if (document.ScreeningLevel1 && document.ScreeningLevel1[0] && document.ScreeningLevel1[0].linkDate) {
//         screenlevel1linkDate = new Date(document.ScreeningLevel1[0].linkDate);
//       }


//       const currentDate = new Date();


//       // Check Prescreening and Prescreeningresult conditions
//       if (Prescreening.length > 0) {
//           const prescreeningStatus = Prescreening[0].Status;
//           const prescreeningResultStatus = Prescreeningresult.status;

          
//           if (prescreeningStatus === "Active") {
//               if (prescreeningResultStatus === "Completed") {
//                   if (ScreeningLevel1.length > 0) {
//                       const screeningStatus = ScreeningLevel1[0].Status;
//                       if (screeningStatus === "Active") {
//                           if (currentDate >= screenlevel1startdate && currentDate <= screenlevel1linkDate) {
//                               navigate("/WebcamNew" , { state: { candidateid: candidateId,positiondata:position }});
//                           } else if (currentDate < screenlevel1startdate) {
//                               navigate("/notstarted");
//                           } else {
//                               navigate("/LinkExpired");
//                           }
//                       } else if (screeningStatus === "Completed") {
//                           navigate("/LinkExpired");
//                       } else {
//                           navigate("/prescreening", { state: { candidateid: candidateId , position:position  } });
//                       }
//                   } else {
//                       navigate("/LinkExpired");
//                   }
//               } else {
//                   if (currentDate >= prescreeningstartDate && currentDate <= prescreeninglinkDate) {
//                       navigate("/prescreening", { state: { candidateid: candidateId,position:position  }});
//                   } else if (currentDate < prescreeningstartDate) {
//                       navigate("/notstarted");
//                   } else {
//                       navigate("/LinkExpired");
//                   }
//               }
//           } else if (prescreeningStatus === "Completed") {
//               if (prescreeningResultStatus === "Completed") {
//                   if (ScreeningLevel1.length > 0) {
//                       const screeningStatus = ScreeningLevel1[0].Status;
//                       if (screeningStatus === "Active") {
//                           if (currentDate >= screenlevel1startdate && currentDate <= screenlevel1linkDate) {
//                               navigate("/WebcamNew" ,{ state: { candidateid: candidateId , positiondata:position }});
//                           } else if (currentDate < screenlevel1startdate) {
//                               navigate("/notstarted");
//                           } else {
//                               navigate("/LinkExpired");
//                           }
//                       } else if (screeningStatus === "Completed") {
//                           navigate("/LinkExpired");
//                       } else {
//                           navigate("/prescreening", { state: { candidateid: candidateId ,position:position  } });
//                       }
//                   } else {
//                       navigate("/LinkExpired");
//                   }
//               } else {
//                   navigate("/LinkExpired");
//               }
//           } else if(prescreeningStatus === "Inactive") {
            
//               navigate("/invalid");
//           }
//           else {            
//             navigate("/prescreening", { state: { candidateid: candidateId , position:position  } });
//         }
//       } else {
// if(ScreeningLevel1.length>0)
// {
//   navigate("/WebcamNew" ,{ state: { candidateid: candidateId , positiondata:position }});
// }
// else
// {
//   navigate("/prescreening", { state: { candidateid: candidateId ,position:position  } });
// }
          
//       }

      const formData = {
        name,
        email,
        mobilenumber,
        pannumber,
        position,
        candidateId: candidateId[0],
        termsAccepted
    };

    await axios.post(`${process.env.REACT_APP_VIDEO_BACKEND_URL}/api/save_consent_form`, formData);
  
  } catch (error) {
      // Handle errors
      console.error('Error checking candidate status:', error);
      setError(error);
  }

  }
    
};
 
 
  return (
    <div className="concernscreen-container">
    <div className="concernscreen-flex-container">
      <div className="concernscreen-terms-card">
        <h2 className="concernscreen-terms-heading">Instructions for Screening Test with AI Avatar Interview</h2>
        <p><strong>Dear Candidate,</strong></p>
      <p>Congratulations on being selected for the screening test! Please carefully read and follow the instructions below to ensure a smooth and successful interview experience:</p>

      <p><strong>1. Preparation:</strong></p>
      <ul>
          <li>Make sure you are in a quiet environment with minimal background noise and distractions.</li>
          <li>Ensure your computer/laptop is fully charged or plugged in to avoid interruptions due to low battery.</li>
      </ul>

      <p><strong>2. Technical Requirements:</strong></p>
      <ul>
          <li>Use a desktop or laptop computer for the interview. Mobile devices are not recommended.</li>
          <li>Ensure you have a stable internet connection with sufficient bandwidth for video and audio streaming.</li>
      </ul>

      <p><strong>3. Software Setup:</strong></p>
      <ul>
          <li>Prior to the interview, ensure your browser (preferably Google Chrome or Firefox) and any require are up to date.</li>
          <li>Allow necessary permissions for accessing your microphone and camera when prompted by interview platform.</li>
      </ul>

      <p><strong>4. Environment:</strong></p>
      <ul>
          <li>Choose a well-lit room to ensure your face is clearly visible on camera.</li>
          <li>Avoid sitting in front of windows or bright lights that could cast shadows or cause glare.</li>
      </ul>

      <p><strong>5. Interview Process:</strong></p>
      <ul>
          <li>You will interact/communicate/discuss with an AI avatar that will ask you interview questions.</li>
          <li>Speak clearly and confidently. The AI avatar will analyze your responses based on content, voice tone, and facial expressions.</li>
          <li>You would be asked to share your screen to demonstrate your skills.</li>
      </ul>

      <p><strong>6. Presentation:</strong></p>
      <ul>
          <li>Maintain good posture and eye contact with the camera to create a positive impression.</li>
      </ul>

      <p><strong>7. Follow Instructions:</strong></p>
      <ul>
          <li>Listen carefully to the questions asked by the AI avatar and respond appropriately.</li>
          <li>If you encounter any technical issues during the interview, remain calm and contact the person who has scheduled interview for you.</li>
      </ul>

      <p><strong>8. Post-Interview:</strong></p>
      <ul>
          <li>After completing the interview, wait for further instructions from the hiring team regarding the next steps in the selection process.</li>
      </ul>
      </div>

      <form onSubmit={handleSubmit} className="concernscreen-form">
        <h3 className='Form-heading'>JOIN THE INTERVIEW</h3>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="concernscreen-input"
          required
        />
        <input
          type="email"
          placeholder="Email ID"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="concernscreen-input"
          required
        />
        <input
          type="text"
          placeholder="Mobile number"
          value={mobilenumber}
          onChange={(e) => setMobilenumber(e.target.value)}
          className="concernscreen-input"
          required
        />
        <input
          type="text"
          placeholder="Pan number"
          value={pannumber}
          onChange={(e) => setPanumber(e.target.value)}
          className="concernscreen-input"
          required
        />
        <div className="concernscreen-checkbox-container">
          <input
            type="checkbox"
            id="terms"
            onClick={handleCheckboxClick}
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
            className="concernscreen-checkbox"
            required
          />
          
          <label htmlFor="terms" className="concernscreen-checkbox-label" onClick={handleCheckboxClick}>
          <a href="#" style={{color:"black",textDecorationLine:'none'}} onClick={(e) => { e.preventDefault(); setShowPopup(true); }}>I accept the terms and conditions<span style={{color:"red"}}>*</span></a>
        </label>
        </div>
        
        <button type="submit" className="concernscreen-button">Join</button>
      </form>
      
    </div>
    {showPopup && (
      <div className="overlay" onClick={() => setShowPopup(false)}>
        <div className="popup-card" onClick={(e) => e.stopPropagation()}>
          <div className="popup-card2">
          <h2 className="concernscreen-terms-heading2">Terms and Conditions</h2>
          <p>I, hereby consent to participate in the screening test conducted by Intellirecruits.</p>
          <ul>
            <li>I consent to the recording of my video, audio, and full system screen during the screening test.</li>
            <li>I understand that these recordings will be used for the purpose of evaluating my performance during the test.</li>
            <li>I acknowledge that my personal details and recordings will be stored securely and will only be accessible to authorized personnel involved in the evaluation process.</li>
            <li>I understand that this consent remains valid throughout my participation in pre & post the interview processes or other processes as defined by the company.</li>
          </ul>

          <div className="concernscreen-checkbox-container2">
          <input
            type="checkbox"
            id="terms"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
            className="concernscreen-checkbox2"
            required
          />
          <p htmlFor="terms" className="concernscreen-checkbox-label2 note" >By signing this consent form, I acknowledge that I have read and understood the terms outlined above, and I agree to abide by them throughout my participation in the screening test.</p>
          </div>
          <div className='close-btn-container'>
            <button className="close-btn" onClick={() => setShowPopup(false)}>Submit</button>
          </div>
        </div>
        </div>
      </div>
    )}
  </div>
);
};

export default ConcernScreen;
