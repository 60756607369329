import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faRecordVinyl, faChartSimple, faUser } from '@fortawesome/free-solid-svg-icons';
import Webcam from 'react-webcam';
import RecordRTC from 'recordrtc';
import { BlobServiceClient, AnonymousCredential } from '@azure/storage-blob';
import { useNavigate } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useLocation } from 'react-router-dom';
import brand1 from "../../components/logo.png";

function WebcamNew() {
  const [recording, setRecording] = useState(false);
  const [screenStream, setScreenStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [azureBlobClient, setAzureBlobClient] = useState(null);
  const [timer, setTimer] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const [excelData, setExcelData] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [commentText, setCommentText] = useState('');
  const [arrowsVisible, setArrowsVisible] = useState(false);
  const [startButtonDisabled, setStartButtonDisabled] = useState(false);
  const[bloburl,setbloburl] = useState('');
  const[url,seturl] =  useState('');
  const[Candidate_id,setid]=useState('');
  const[candidateobj_id,setCandidateobj_id]=useState('');
  const [error, setError] = useState(null);
  const [intQuestions, setIntQuestions] = useState([]);
  const [customQuestion, setcustomQuestion] = useState([]);
  const[length,setLength] =  useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isRecording1, setIsRecording1] = useState(false);
  const [transcripts12, setTranscripts12] = useState('');
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [Name,SetName] = useState('');
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const location = useLocation();
  const { candidateid ,positiondata} = location.state || {};

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

 

  useEffect(() => {
    const handleInterviewQuestion = async () => {
      try {
        if (!candidateid) {
          console.error('Candidate ID is not defined:', candidateid);
          return;
        }
 
        // setIsLoadingscreen(true); // Start loading state
        var position = positiondata.replace(/%20/g, " ");
        const response = await axios.get(`${process.env.REACT_APP_VIDEO_BACKEND_URL}/api/schedule/get_schedule/${candidateid}/${position}`);
        setIntQuestions(response.data);
        SetName(response.data.candidate_name);
        setCandidateobj_id(response.data._id);
       // const status = response.data[0].status;
        const start = new Date(response.data.ScreeningLevel1[0].startDate);
        const end = new Date(response.data.ScreeningLevel1[0].linkDate);
        const nowUTC = new Date();
        const ISTOffset = 5.5 * 60 * 60 * 1000;
        const nowIST = new Date(nowUTC.getTime() + ISTOffset);
 
        const isValid = nowIST.getTime() >= start.getTime() && nowIST.getTime() <= end.getTime();
        if (isValid) {
          
          const interviewQuestions = response.data.ScreeningLevel1[0].interviewquestions;
          const mappedData = interviewQuestions;
          setTotal(mappedData.length);
          setExcelData(mappedData);
          
          setCommentText(mappedData[0].Questions);
        } else  {
          navigate('./notstarted');
        }

      } catch (error) {
        console.error('Error fetching questions:', error);
        setError(error);
      }
    };
 
    handleInterviewQuestion();
  }, [candidateid, navigate]);

  useEffect(() => {
    const initializeAzureBlobClient = async () => {
      const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN; // Replace with provided SAS token
      const blobServiceClient = new BlobServiceClient(`${process.env.REACT_APP_BLOB_SERVICE_URL}/?${sasToken}`, new AnonymousCredential());
      setAzureBlobClient(blobServiceClient);
    };
 
    initializeAzureBlobClient();
  }, []);
 
  const handleClick = () => {
    const currentQuestion = excelData[0];
      setTranscripts12(transcript);
      if(transcript.length>0)
      {
        sendDataToBackend(currentQuestion, transcript);
      }
    resetTranscript()
    excelData.shift();
    if (excelData.length > 0) {
      if (current + 1 >= total) {
        setCurrent(total);
         setArrowsVisible(false);
      } else {
        setCurrent(current + 1);
      }
      setCommentText(excelData[0].Questions);
      setVideoUrl(excelData[0].video_url);
    }
 
 
  };
 
 const handleManualStop =() =>{

 }
   const sendDataToBackend = async (value,transcriptvalue) => {
    try {
      value["recognizedtext"] = transcript;
      var position = positiondata.replace(/%20/g, " ");
      const response = await axios.post(`${process.env.REACT_APP_VIDEO_BACKEND_URL}/api/data`, { transcript,transcriptvalue,value,candidateobj_id,position});
      resetTranscript()
      setTranscripts12('');
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };
  
  const startRecordingAzure = async () => {
    try {
 
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: false 
      });
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100
        },
        video: false
      });
      const combinedStream = new MediaStream([
        ...screenStream.getVideoTracks(),
        ...audioStream.getAudioTracks()
      ]);
      const recorder = new RecordRTC(combinedStream, { type: 'video' });
      setMediaRecorder(recorder);
      setScreenStream(screenStream);
      recorder.startRecording();
      setRecording(true);
      startTimer();
      const videoUrlToPlay = excelData[0].video_url;
      setVideoUrl(videoUrlToPlay);
 
    } catch (error) {
      console.error('Error starting screen recording:', error);
    }
  };
  const stopRecordingAzure = async () => {
    const currentQuestion = excelData[0];
    if(transcript.length>0)
      {
    sendDataToBackend(currentQuestion,transcript);
      }
 
    try {
      mediaRecorder.stopRecording(() => {
        const blob = mediaRecorder.getBlob();
        uploadRecordedVideo(blob);
      });
      screenStream.getTracks().forEach(track => track.stop());
      setRecording(false);
      stopTimer();
    } catch (error) {
      console.error('Error stopping screen recording:', error);
    }
  };
  const uploadRecordedVideo = async (blob) => {
    try {
      if (!azureBlobClient) {
        console.error('Azure Blob Service Client is not initialized.');
        return;
      }
      const containerName = process.env.REACT_APP_CONTAINER_NAME;// Change to your container name   
      const containerClient = azureBlobClient.getContainerClient(containerName);
      const blobName = `recorded_video_${generateUniqueId()}.webm`; // Unique name with ID
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      await blockBlobClient.uploadData(blob);
      
      const dburl = `${process.env.REACT_APP_BLOB_SERVICE_URL}`+"/"+`${process.env.REACT_APP_CONTAINER_NAME}/` + blobName;
      setbloburl(dburl);

    } catch (error) {
      console.error('Error uploading recorded video:', error);
    }
  };
  const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };


  if (bloburl !== '') {
   
    (async () => {
      try {
        var position = positiondata.replace(/%20/g, " ");
        const response = await axios.post(`${process.env.REACT_APP_VIDEO_BACKEND_URL}/api/blobtomongo`, {bloburl,candidateid,candidateobj_id,position});
        if (response.status === 200) {
          setIsLoading(false);
          navigate("/InterviewEnd" ,{state: {candidateid:candidateid}});
        } else {
          throw new Error(`Error sending data: ${response.statusText}`);
        }
      } catch (error) {
        console.error('Error sending data:',error);
      }
    })();
  }
  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer(prevTimer => prevTimer + 1);
    }, 1000);
    setTimerInterval(interval);
  };
  const stopTimer = () => {
    clearInterval(timerInterval);
    setTimer(0); // Reset the timer
  };
  // Function to format time in HH:MM:SS format
  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  const handleStartRecording = () => {
    startRecordingAzure();
    setArrowsVisible(true);
    setStartButtonDisabled(true); // Disable the start button
    setIsRecording1(true);
    SpeechRecognition.startListening({ continuous: true });
  };
 
  const handleStopRecording = () => {    
    stopRecordingAzure();
   setIsLoading(true);
  };
  return (
    <div className="app">
      <div className="top-dashboard">
        <div className="logo">
          <img className="logo" src={brand1}/>
        </div>
        <div className="user-profile">
          <span className="icon-user"><FontAwesomeIcon icon={faUser} /></span>
          <span className='user-class'>{Name}</span>
        </div>
      </div>
      <div>
      {!isOnline && (
        <div className="network-status">
          <p>Connection lost. Trying to reconnect...</p>
          <div className="spinner"></div>
        </div>
      )}
    </div>
      <div className='web-avatar-conatiner'>
        <div className={`Webcam-container ${recording ? 'recording' : ''}`}>
          <Webcam
            audio={false}
            mirrored={true}
            screenshotFormat="image/jpeg"
            className="webcam"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              borderRadius: "8px"
            }}
          />
        </div>
 
        <div className='avatar-area'>
          <div className='avatar-container'>
            {videoUrl && (
              <video autoPlay controls={false} src={videoUrl} style={{ width: "100%", height: "83%", borderRadius: "8px" }} />
            )}
            {videoUrl && (
              <div className='text-container'>
                <div className='bar-icon'>
                  <FontAwesomeIcon icon={faChartSimple} />
                </div>
                <textarea className="textarea-avatar" value={commentText} ></textarea>
              </div>
            )}
          </div>
        </div>
 
      </div>
 
      <div className="container-fluid">
        <div className="row">
              
        <div className="col-10 d-flex button-container justify-content-start">
            <button type="button" className={`button-success btn btn-success ${startButtonDisabled ? 'disabled' : ''}`} onClick={handleStartRecording} disabled={startButtonDisabled}>
              Start Recording
            </button>
            {arrowsVisible && ( /* Conditionally render the arrow container */
              <div className='pagination'  style={{ cursor: 'pointer' }}>
             
                <div className='question-number'>
                  <p> <span className="current" data-current={current}>{current}</span> of <span className="total" data-total={total}>{total}</span> Questions </p>
                </div>
 
                <div className='right-arrow'  style={{ cursor: 'pointer' }} onClick={isRecording ? handleManualStop : handleClick}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            )}
            <button type="button" className='button-danger' onClick={handleStopRecording} disabled={!recording}>Stop Recording</button>
                 
            {isLoading && (
        <div className="overlay">
          <div className="spinner-container">
          <div className="spinner"></div>
          <div className="loading-text">Please wait, processing...</div>
          </div>
        </div>
          )}
          </div>


          <div className="col-2 custom1">
            <div className="timer-bg">
              <span className='red-circle'><FontAwesomeIcon icon={faRecordVinyl} /></span>
              <span className='timer-font'>{formatTime(timer)}</span>
            </div>
          </div>
 
        </div>
      </div>
    </div>
  );
 
};
 
export default WebcamNew;