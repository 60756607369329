import React from 'react';
import '../../styles/InvalidURL.css'; // Import the CSS file for styling

const InvalidURL = () => {

  return (
    <div className="invalid-url-container">
      <i className="fas fa-exclamation-triangle invalid-url-icon"></i>
      <h1 className="invalid-url-title">Invalid URL</h1>
      <p className="invalid-url-message">
        The URL you have entered is invalid. Please check the URL and try again.
      </p>

        <p className="invalid-url-close-message">
          You can now close this window manually.
        </p>
    
    </div>
  );
};

export default InvalidURL;
