import React from 'react';
import '../../styles/InterviewNotStarted.css'; 

const InterviewNotStarted = () => {


  return (
    <div className="interview-not-started-container">
      <i className="fas fa-clock interview-not-started-icon"></i>
      <h1 className="interview-not-started-title">Interview Not Started</h1>
      <p className="interview-not-started-message">
        Please return at the scheduled time for your interview.
      </p>

        <p className="interview-not-started-close-message">
          You can now close this window manually.
        </p>
   
    </div>
  );
};

export default InterviewNotStarted;
